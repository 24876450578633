import { createSelector } from '@reduxjs/toolkit';
import { selectListScene } from './detailSceneSelectors';
import {
  onGetDetailPlugin,
  onGetFormCapturesAttachedToSceneIds,
  onGetSceneIdsAttachedToFormCaptures,
  onGetFormCapturesAttachedToHotspotIds,
  onGetHotspotIdsAttachedToFormCaptures,
  onGetHotspotTypesAttachedToFormCaptures,
  onGetFormCapturesAttachedToHotspotTypes,
} from '../utils';
import { selectDomain } from './mainSelectors';
import {
  selectDetailProject,
  selectLanguageProjectSelected,
} from './detailProjectSelectors';
import { getTextMultiLang } from '@utils/helper';
import { selectDefaultLanguageProject } from './multiLanguageSelector';

/**
 * SECTION: PLUGIN SELECTORS
 */

export const selectDataMap = createSelector([selectDetailProject], project =>
  project?.project_type === 'matterport' && project?.lat && project?.lng
    ? {
        markers: [
          {
            id: 'yX8lLEykClAEt9MollWiS',
            lat: project?.lat,
            lng: project?.lng,
          },
        ],
      }
    : onGetDetailPlugin(project?.plugins, 'map')?.dataMap || undefined,
);

export const selectConfigMap = createSelector(
  [selectDetailProject],
  project => onGetDetailPlugin(project?.plugins, 'map')?.configMap || undefined,
);

export const selectListFloorplan = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) =>
    (onGetDetailPlugin(project?.plugins, 'floorplan')?.listFloorplan || []).map(
      item => ({
        ...item,
        name: getTextMultiLang(
          item.name,
          project?.keyTranslations,
          languageProjectSelected,
          defaultLanguageProject,
        ),
      }),
    ),
);

export const selectConfigFloorplan = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'floorplan')?.configFloorplan ||
    undefined,
);

export const selectConfigCollaboration = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'collaboration')?.configCollaboration ||
    undefined,
);

export const selectConfigControlbar = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'controlbar')?.configControlbar ||
    undefined,
);

export const selectConfigLogo = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'logo')?.configLogo || undefined,
);

export const selectConfigScreenshotCapture = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'screenshotCapture')
      ?.configScreenshotCapture || undefined,
);

export const selectConfigNadir = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'nadir')?.configNadir || undefined,
);

export const selectConfigProtectPassword = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'protectPassword')
      ?.configProtectPassword || undefined,
);

export const selectConfigCopyRight = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) => {
    const config = onGetDetailPlugin(
      project?.plugins,
      'copyRight',
    )?.configCopyRight;
    return config
      ? {
          ...config,
          author: getTextMultiLang(
            config.author,
            project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
          description: getTextMultiLang(
            config.description,
            project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
        }
      : undefined;
  },
);

export const selectConfigPopupIntro = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) => {
    const config = onGetDetailPlugin(
      project?.plugins,
      'popupIntro',
    )?.configPopupIntro;
    return config
      ? {
          ...config,
          textClose: getTextMultiLang(
            config.textClose,
            project?.keyTranslations,
            languageProjectSelected,
            defaultLanguageProject,
          ),
        }
      : undefined;
  },
);

export const selectListCallToAction = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) =>
    (
      onGetDetailPlugin(project?.plugins, 'callToAction')?.listCallToAction ||
      []
    ).map(item => ({
      ...item,
      title: getTextMultiLang(
        item.title,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
    })),
);

// SECTION: PLUGIN FORM CAPTURE

export const selectListFormCapture = createSelector(
  [
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (project, languageProjectSelected, defaultLanguageProject) =>
    (
      onGetDetailPlugin(project?.plugins, 'formCapture')?.listFormCapture || []
    ).map(item => ({
      ...item,
      title: getTextMultiLang(
        item.title,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      buttonTxt: getTextMultiLang(
        item.buttonTxt,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      description: getTextMultiLang(
        item.description,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      fields: item.fields.map(field => ({
        ...field,
        label: getTextMultiLang(
          field.label,
          project?.keyTranslations,
          languageProjectSelected,
          defaultLanguageProject,
        ),
      })),
    })),
);

export const selectConfigFormCapture = createSelector(
  [selectDetailProject],
  project =>
    onGetDetailPlugin(project?.plugins, 'formCapture')?.configFormCapture || [],
);

export const selectHotspotSelectedTemporary = createSelector(
  [selectDomain],
  state => state.hotspotSelectedTemporary || null,
);

export const selectIsVisibleFormCapture = createSelector(
  [selectDomain],
  state => state.isVisibleFormCapture,
);

export const selectFormCaptureConfigSelected = createSelector(
  [selectDomain],
  state => state.formCaptureConfigSelected || undefined,
);

export const selectFormCaptureSelected = createSelector(
  [selectListFormCapture, selectFormCaptureConfigSelected],
  (list, config) =>
    config && config.form
      ? list.filter(item => item.id === config?.form)[0] || undefined
      : undefined,
);

export const selectListSceneIdToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onGetSceneIdsAttachedToFormCaptures(config),
);

export const selectListFormCaptureConfigCorrespondingToListSceneId =
  createSelector([selectConfigFormCapture], config =>
    onGetFormCapturesAttachedToSceneIds(config),
  );

export const selectListHotspotTypeToOpenFormCapture = createSelector(
  [selectConfigFormCapture],
  config => onGetHotspotTypesAttachedToFormCaptures(config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotType =
  createSelector([selectConfigFormCapture], config =>
    onGetFormCapturesAttachedToHotspotTypes(config),
  );

export const selectListHotspotIdToOpenFormCapture = createSelector(
  [selectListScene, selectConfigFormCapture],
  (listScene, config) =>
    onGetHotspotIdsAttachedToFormCaptures(listScene, config),
);

export const selectListFormCaptureConfigCorrespondingToListHotspotId =
  createSelector(
    [selectListScene, selectConfigFormCapture],
    (listScene, config) =>
      onGetFormCapturesAttachedToHotspotIds(listScene, config),
  );

// !SECTION: FORM CAPTURE
