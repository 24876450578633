import { createSelector } from '@reduxjs/toolkit';
import { getMediaMultiLang, getTextMultiLang } from '@utils/helper';
import { selectDomain } from './mainSelectors';
import { getGroups } from '../utils';
import {
  selectDetailProject,
  selectLanguageProjectSelected,
} from './detailProjectSelectors';
import { selectDefaultLanguageProject } from './multiLanguageSelector';

export const selectIsFirstScene = createSelector(
  [selectDomain],
  state => state.isFirstScene,
);

export const selectListScene = createSelector(
  [
    selectDomain,
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (state, project, languageProjectSelected, defaultLanguageProject) =>
    (state.listScene || []).map(scene => ({
      ...scene,
      title: getTextMultiLang(
        scene.title,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      background_sound_multi_lang: getMediaMultiLang(
        scene.background_sound_multi_lang,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
      hotspots: scene.hotspots.map(hotspot => ({
        ...hotspot,
        title: getTextMultiLang(
          hotspot.title,
          project?.keyTranslations,
          languageProjectSelected,
          defaultLanguageProject,
        ),
        itemPrd: hotspot.itemPrd
          ? {
              ...hotspot.itemPrd,
              name: getTextMultiLang(
                hotspot.itemPrd?.name,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
              buttonText: getTextMultiLang(
                hotspot.itemPrd?.buttonText,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
              currency: getTextMultiLang(
                hotspot.itemPrd?.currency,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
              price: getTextMultiLang(
                hotspot.itemPrd?.price,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
            }
          : undefined,
        config: {
          ...hotspot.config,
          style: {
            ...hotspot.config?.style,
            callout: {
              ...hotspot.config?.style?.callout,
              text: getTextMultiLang(
                hotspot.config?.style?.callout?.text,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
            },
          },
          image: {
            ...hotspot.config?.image,
            galleries: hotspot.config?.image?.galleries?.map(media => ({
              ...media,
              name: getTextMultiLang(
                media.name,
                project?.keyTranslations,
                languageProjectSelected,
                defaultLanguageProject,
              ),
            })),
          },
          compact: hotspot.config?.compact?.map(item =>
            item.type === 'image'
              ? {
                  ...item,
                  config: {
                    ...item.config,
                    galleries: item.config?.galleries?.map(mediaCompact => ({
                      ...mediaCompact,
                      name: getTextMultiLang(
                        mediaCompact.name,
                        project?.keyTranslations,
                        languageProjectSelected,
                        defaultLanguageProject,
                      ),
                    })),
                  },
                }
              : item,
          ),
        },
      })),
    })),
);

export const selectListGroup = createSelector(
  [
    selectListScene,
    selectDetailProject,
    selectLanguageProjectSelected,
    selectDefaultLanguageProject,
  ],
  (scenes, project, languageProjectSelected, defaultLanguageProject) => {
    const groups = getGroups(scenes, project?.groups)?.map(item => ({
      ...item,
      name: getTextMultiLang(
        item.name,
        project?.keyTranslations,
        languageProjectSelected,
        defaultLanguageProject,
      ),
    }));
    return groups.length === 1 && groups[0].id === 'uncategory'
      ? groups
      : groups.filter(item => item.id !== 'uncategory');
  },
);

export const selectListSceneByGroup = createSelector(
  [selectListGroup],
  listGroup =>
    listGroup
      .reduce((result, item) => {
        result.push(item.scenes);
        return result;
      }, [])
      .flat(),
);

export const selectSceneSelected = createSelector(
  [selectDomain, selectListScene],
  (state, scenes) =>
    state.sceneSelected
      ? {
          ...scenes.find(item => item.id === state.sceneSelected.id),
          config: state.sceneSelected.config,
        }
      : undefined,
);

export const selectPrevSceneSelected = createSelector(
  [selectDomain],
  state => state.prevSceneSelected || undefined,
);

export const selectHotspotSelected = createSelector(
  [selectDomain],
  state => state.hotspotSelected || undefined,
);

export const selectDetailFirstScene = createSelector(
  [selectDetailProject, selectListScene, selectListGroup],
  (project, listScene, listGroup) =>
    project?.config?.setting?.general?.first_scene
      ? listScene.filter(
          item => item.id === project?.config?.setting?.general?.first_scene,
        )[0] || listGroup[0].scenes[0]
      : listGroup.length
      ? listGroup[0].scenes[0]
      : undefined,
);
