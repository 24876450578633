import { createSelector } from '@reduxjs/toolkit';
import { selectDetailProject } from './detailProjectSelectors';

export const selectDefaultLanguageProject = createSelector(
  [selectDetailProject],
  project => project?.languages?.find(item => item.default),
);

export const selectLanguagesProject = createSelector(
  [selectDetailProject],
  project => project?.languages,
);

export const selectKeyTranslationsProject = createSelector(
  [selectDetailProject],
  project => project?.keyTranslations,
);
